.component-panel{
    max-height: 132.9px;
    height: calc((42.045vw - 16px) / 1.7778);
    padding: 0.5% 2% 3.5% 2%;
}

.component-panel .image{
    margin: auto;
    height: calc((28vw - 16px) / 1.7778);
    width: auto;
    max-height: 85.48px
}

.component-panel .message{
    white-space: pre-line;
    width: 80%;
    margin-top: 1%;
}

.component-panel .text{
    color:orange;
}

.component-panel .text{
    font-size: 1.32rem;
}

@media (max-width: 600px) {
    .component-panel .text{
        font-size: 3.5vw;
    }
}

.component-panel .roadway{
    color:#8f8f8f;
    font-size: 4vmin;
    text-align: left;
    font-size: calc(10px + 2vmin);
}
