@import url(https://fonts.googleapis.com/css?family=Teko:300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.junctionIcon {
    background-color: orange;
    font-weight: bold;
    font-size: 0.9em;
    padding: 0.1em;
    margin: auto;
    line-height: 0.9em;
    text-align: center;
    vertical-align: middle;
    width: 1em;
}

.junctionIcon span {
    letter-spacing: 0.1em;
    margin-right: -0.1em;
}
.component-cctv {
    position: relative;

    _display: inline-grid;
    _overflow: hidden;
    _margin-bottom: 20px;
    /* height: 56.25vw; */
    max-height: 328.48px;
    height: calc((100vw - 16px) / 1.7778);
}

/* 1.7778 = 512/288 */
.component-cctv .header{
    position: absolute;
    display: flex;
    padding: 0 10px;
    background-color: white;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    font-weight: bold;

}

.component-cctv .header .junctionIcon{
    margin-right: 0.3em;
}
/* applied when CardMedia component=image*/
/* 56.25%; // 16:9 */
.component-cctv div.image{ 
    height: 0;
    padding-top: 56.25%; 
}



/* .component-cctv .image {
    border: 4px solid #999;
    border-radius: 12px;
    display: inline-flex;
    /* width:512px;
    height:288px; /
    width: 100%;
    height: 100%;
} */

.component-cctv .image > *{
    position: absolute;
    width: 100%;
    height: 100%;
}

.component-cctv .image #topWrap{
    _-webkit-filter: drop-shadow(0px 0px 3px yellow);
    _        filter: drop-shadow(0px 0px 3px yellow);
}
.component-cctv .image img#top{
    _-webkit-clip-path: polygon(98% 33%, 92% 25%, 84% 21%, 83% 27%, 76% 33%, 71% 36%, 66% 41%, 62% 47%, 59% 55%, 57% 62%, 56% 70%, 55% 78%, 54% 87%, 54% 99%, 83% 100%, 78% 86%, 76% 79%, 75% 72%, 74% 64%, 73% 56%, 73% 50%, 76% 42%, 80% 36%, 84% 32%, 88% 29%, 91% 30%, 91% 36%, 89% 41%, 93% 45%, 96% 42%, 98% 38%);
    _        clip-path: polygon(98% 33%, 92% 25%, 84% 21%, 83% 27%, 76% 33%, 71% 36%, 66% 41%, 62% 47%, 59% 55%, 57% 62%, 56% 70%, 55% 78%, 54% 87%, 54% 99%, 83% 100%, 78% 86%, 76% 79%, 75% 72%, 74% 64%, 73% 56%, 73% 50%, 76% 42%, 80% 36%, 84% 32%, 88% 29%, 91% 30%, 91% 36%, 89% 41%, 93% 45%, 96% 42%, 98% 38%);
    _-webkit-clip-path: url('/media/filters/049_n.svg#img1');
    _        clip-path: url('/media/filters/049_n.svg#img1');
    /* iOS support */
    /*Chrome,Safari*/
    -w_ebkit-clip-path: polygon(113px 289px,120px 233px,131px 184px,153px 149px,182px 123px,205px 109px,239px 95px,270px 89px,303px 87px,338px 88px,390px 94px,371px 99px,341px 111px,313px 133px,290px 162px,278px 192px,273px 215px,266px 255px,262px 289px,445px 289px,402px 224px,381px 191px,374px 165px,377px 138px,390px 118px,426px 98px,444px 93px,454px 100px,463px 110px,471px 121px,477px 132px,500px 110px,502px 106px,497px 89px,490px 83px,473px 76px,461px 71px,443px 66px,426px 62px,423px 66px,426px 71px,428px 78px,420px 83px,398px 86px,373px 82px,337px 78px,311px 77px,285px 77px,246px 82px,195px 96px,148px 113px,111px 136px,80px 166px,47px 207px,21px 248px,-2px 289px);
    width: calc(512px*0.7);
    height: calc(288px*0.7);
 
}
/* .component-cctv .image::after, .component-cctv .image > :not(#topWrap) {
    content: '';
    display: block;
    width: calc(512px*0.7);
    height: calc(288px*0.7);
    filter: blur(2.5px) ;
    /* position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(10px) brightness(20%);
    transform: scale(2.5);
    z-index: -1; */
   /* _background-image: url('http://www.vialitoral.com/imagens/049/snap_c1.jpg');

  } */

.day .component-cctv img {
    box-shadow: black 0px 0px 31px -10px;
}

.component-cctv .linearProgress{
    position: absolute;
    bottom:0;
    width: 100%;
    transition:visibility 0.3s linear,opacity 0.3s linear;
}

.component-cctv:not(.fetching) .linearProgress{
    visibility: hidden;
    opacity: 0;
}
.component-panel{
    max-height: 132.9px;
    height: calc((42.045vw - 16px) / 1.7778);
    padding: 0.5% 2% 3.5% 2%;
}

.component-panel .image{
    margin: auto;
    height: calc((28vw - 16px) / 1.7778);
    width: auto;
    max-height: 85.48px
}

.component-panel .message{
    white-space: pre-line;
    width: 80%;
    margin-top: 1%;
}

.component-panel .text{
    color:orange;
}

.component-panel .text{
    font-size: 1.32rem;
}

@media (max-width: 600px) {
    .component-panel .text{
        font-size: 3.5vw;
    }
}

.component-panel .roadway{
    color:#8f8f8f;
    font-size: 4vmin;
    text-align: left;
    font-size: calc(10px + 2vmin);
}

#slider.MuiSlider-root{
    color: orange;
    position: fixed;
}
#slider.MuiSlider-root:not(.MuiSlider-vertical){
    right: 10vw;
    width: 60vw;
    bottom: 0;
}


#slider.MuiSlider-vertical {
    padding-right: 36px;
    right: 0;
    bottom: 86px;
    height: calc(min(80vh, 5in) - 86px)
}



/* update label style without a new component */
#slider .MuiSlider-valueLabel {
    top:0;
    right: 20px;
    transform-origin: center right;
}

/* label circle */
#slider .MuiSlider-valueLabel > span {
    transform: rotate(0deg);
    width: inherit;
    height: inherit;
    border-radius: 0;
}

/* label */
#slider .MuiSlider-valueLabel > span > span {
    transform: rotate(0deg);
    white-space: nowrap;
    padding: 10px
}
/* mark */
#slider .MuiSlider-mark {
    width: 4px;
    height: 4px;
    border-radius: 2px;
    margin: -1px;
}
#slider.MuiSlider-vertical  .MuiSlider-mark{
    margin: -2px;
}
#slider .MuiSlider-thumb {
    margin-left: -5px;
}
/* mark */
#slider .MuiSlider-mark.red, #slider .MuiSlider-mark.blue {
    width: 6px;
    height: 6px;
    border-radius: 3px;
    margin: -3px;
}

#slider .MuiSlider-mark.red {
    background-color: red;
}

#slider .MuiSlider-mark.blue {
    background-color: #00f;
}
.App {
  text-align: center;
  font-family: 'Teko', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

body {
  background-color: #282c3429;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: calc(50vh - 29.21875vw + 8px) 8px; /* vw - half of card height to width ratio*/
  transition:visibility 0.3s linear,opacity 0.3s linear;
}

.App-header.day {
  background-color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* stages */
:not(.rendered) > .App-header {
  visibility: hidden;
  opacity:0;
}
