#slider.MuiSlider-root{
    color: orange;
    position: fixed;
}
#slider.MuiSlider-root:not(.MuiSlider-vertical){
    right: 10vw;
    width: 60vw;
    bottom: 0;
}


#slider.MuiSlider-vertical {
    padding-right: 36px;
    right: 0;
    bottom: 86px;
    height: calc(min(80vh, 5in) - 86px)
}



/* update label style without a new component */
#slider .MuiSlider-valueLabel {
    top:0;
    right: 20px;
    transform-origin: center right;
}

/* label circle */
#slider .MuiSlider-valueLabel > span {
    transform: rotate(0deg);
    width: inherit;
    height: inherit;
    border-radius: 0;
}

/* label */
#slider .MuiSlider-valueLabel > span > span {
    transform: rotate(0deg);
    white-space: nowrap;
    padding: 10px
}
/* mark */
#slider .MuiSlider-mark {
    width: 4px;
    height: 4px;
    border-radius: 2px;
    margin: -1px;
}
#slider.MuiSlider-vertical  .MuiSlider-mark{
    margin: -2px;
}
#slider .MuiSlider-thumb {
    margin-left: -5px;
}
/* mark */
#slider .MuiSlider-mark.red, #slider .MuiSlider-mark.blue {
    width: 6px;
    height: 6px;
    border-radius: 3px;
    margin: -3px;
}

#slider .MuiSlider-mark.red {
    background-color: red;
}

#slider .MuiSlider-mark.blue {
    background-color: #00f;
}