.junctionIcon {
    background-color: orange;
    font-weight: bold;
    font-size: 0.9em;
    padding: 0.1em;
    margin: auto;
    line-height: 0.9em;
    text-align: center;
    vertical-align: middle;
    width: 1em;
}

.junctionIcon span {
    letter-spacing: 0.1em;
    margin-right: -0.1em;
}